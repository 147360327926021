@use "mixins" as *
.betSlipHeader
  @include flex
  @include bold
  justify-content: space-between
  background: var(--betslip_headerBg)
  line-height: 12px
  position: relative
  overflow: hidden
  height: var(--middleArea_headerHeight)
  border-radius: var(--middleArea_headerRadius)
  box-shadow: var(--base_shadowForScroll)
  z-index: 2
  width: calc(100% - var(--scroll_width))
  text-transform: var(--base_textTransform)
  border-bottom: 1px solid var(--betslip_headerBorderBottom)
  padding-bottom: var(--betslip_headerPadding)
  font-size: var(--betslip_headerFontSize)
  &:after
    content: var(--base_beforeContent)
    position: absolute
    height: 1px
    background: var(--betslip_borderLight)
    border-bottom: 1px solid var(--betslip_borderDark)
    border-top: 1px solid var(--betslip_borderDark)
    bottom: 0
    left: 0
    width: 100%
  span
    color: rgb(var(--primaryColor))
    padding: 9px 10px 7px
    &:hover:not(.active)
      @include pointer
    &.active,
    &:hover
      color: rgb(var(--secondaryColor))
    &.active
      color: rgb(var(--thirdaryColor))
