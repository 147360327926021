@use "mixins" as *
.outcomeItem
  @include pointer
  background: var(--outcome_bg)
  border: 1px solid var(--outcome_border)
  border-radius: var(--outcome_radius)
  margin: var(--outcome_margin)
  text-shadow: 0 -1px rgba(0, 0, 0, .8)
  color: rgb(var(--secondaryColor))
  padding: 0 6px
  min-height: var(--outcome_height)
  overflow: hidden
  @include flex
  width: 100%

  .outcomeItemName
    flex: 1
    @include ellipsis
    margin-right: 5px
    line-height: 14px

  &:hover
    background: var(--outcome_hoverBg)

  &.selected
    background: var(--outcome_selectedBg)
    color: var(--outcome_selectedColor)
    .outcomeItemValue
      color: var(--outcome_selectedValueColor)

  &:hover,
  &.selected
    text-shadow: none
    @include pointer

.outcomeItemValue
  font-family: var(--specialFont)
  color: var(--outcome_valueColor)

.disabled
  pointer-events: none
  opacity: 0.5

i.increasedIcon,
i.decreasedIcon
  background: var(--icon-increased) no-repeat
  width: 7px
  height: 9px
  display: inline-block
  margin-top: 1px
  vertical-align: top !important

  &:after
    content: ""
    opacity: 0
    animation: arrow-opacity 0.5s linear 3
    margin: -5px 0 0 -2px
    background: var(--icon-increased-active) no-repeat
    width: 11px
    height: 13px
    display: inline-block
  @keyframes arrow-opacity
    100%
      opacity: 1

i.decreasedIcon
  background: var(--icon-decreased) no-repeat

  &:after
    background: var(--icon-decreased-active)
