@use "mixins" as *
.sportItem
  @include flex
  font-weight: var(--base_fontWeight)
  color: rgb(var(--primaryColor))
  border-top: var(--sidebar_liveSportItemBorderTop)
  border-bottom: 1px solid var(--sidebar_liveSportItemBorderBottom)
  font-size: var(--sidebar_sportItemFontSize)
  text-transform: var(--base_textTransform)
  text-shadow: 0 -1px rgba(0, 0, 0, .4)
  height: var(--sidebar_sportItemHeight)
  @include pointer
  position: relative
  overflow: hidden
  background: var(--sidebar_liveSportItemBg)
  padding: 0 8px 1px 10px
  z-index: 0
  white-space: nowrap
  .arrow + span
    flex: 1 auto
  &:hover
    background: var(--sidebar_liveSportItemBgOpened)
    .headerBg
      opacity: 1

  &.opened
    color: rgb(var(--secondaryColor))
    background: var(--sidebar_liveSportItemBgOpened)
    .headerBg
      opacity: 1
    .arrow
      margin: 0 8px 0 0
      transform: rotate(0)

.headerBg
  position: absolute
  right: 0
  top: 0
  z-index: -1
  visibility: var(--sidebar_liveSportItemHeaderBgVisibility)
  opacity: 0.6
  filter: var(--sportHeaderBgFilter)

.arrow
  transform: rotate(-90deg)
  margin: 4px 5px 0 3px
  transform-origin: 25%
  transition: all 0.14s
  min-width: 10px

.topLeaguesContainer,
.topLeaguesContainer > div
  overflow: hidden

.topLeaguesContainer > div
  border-bottom: var(--sidebar_topLeauguesContainerBorder)
  background: var(--sidebar_liveTournamentContainerBg)
