@use "mixins" as *
.sport
  @include flex
  padding: 4px 8px
  background: var(--sidebar_sportItemBg)
  border-top: var(--sidebar_sportItemBorderTop)
  border-bottom: 1px solid var(--sidebar_sportItemBorderBottom)
  min-height: var(--sidebar_sportItemHeight)
  color: rgb(var(--primaryColor))
  font-weight: var(--base_fontWeight)
  font-size: var(--sidebar_sportItemFontSize)
  line-height: 14px
  text-transform: var(--base_textTransform)

  :global(.svg-sport-icon)
    margin-right: 8px
    opacity: var(--sidebar_sportIconOpacity)
    width: 20px
    min-width: 20px
    height: 20px
    border-radius: 100%
    background: var(--sidebar_sportIconBg)
    border: var(--sidebar_sportIconBorder)
    box-shadow: var(--sidebar_sportIconShadow)
    padding: 1px
    @include flex
    justify-content: center

    > span
      @include flex

  &:hover
    color: rgb(var(--secondaryColor))
    @include pointer

    :global(.svg-sport-icon)
      opacity: 1

    svg
      path
        fill: rgb(var(--secondaryColor)) !important

  &.opened
    background: var(--sidebar_sportItemBgOpened)
    color: rgb(var(--secondaryColor))
    border-top-color: var(--sidebar_sportItemBgOpenedBorderTop)

    :global(.svg-sport-icon)
      opacity: 1

    svg
      path
        fill: rgb(var(--secondaryColor)) !important

  &.selected
    position: relative
    color: rgb(var(--secondaryColor))

    &:before
      content: var(--base_beforeContent)
      width: 2px
      height: 80%
      position: absolute
      left: 0
      top: 50%
      transform: translateY(-50%)
      background: var(--sidebar_sportItemSelectedIconFill)

    :global(.svg-sport-icon)
      opacity: 1

      svg
        path
          fill: var(--sidebar_sportItemSelectedIconFill) !important

.gameCount
  font-weight: normal
  background: var(--sidebar_gameCountBg)
  border: 1px solid var(--sidebar_gameCountBorder)
  box-shadow: var(--sidebar_gameCountShadow)
  width: 36px
  height: 20px
  @include flex
  justify-content: center
  font-size: var(--base_fontSize)
  font-weight: var(--sidebar_gameCountFontWeight)
  margin-left: auto
