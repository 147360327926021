@use "mixins" as *
.selector
  position: absolute
  left: 58px
  z-index: 1000
  min-width: 250px
  width: 250px

  &:after
    content: ""
    width: 0
    height: 0
    border-style: solid
    border-width: 6px 6px 6px 0
    border-color: transparent var(--simpleSidebar_popoverBg) transparent transparent
    position: absolute
    left: -6px
    top: 27px

  &:before
    content: ""
    width: 0
    height: 0
    border-style: solid
    border-width: 7px 7px 7px 0
    border-color: transparent var(--base_borderColor) transparent transparent
    position: absolute
    left: -7px
    top: 26px

  > div
    border: 4px solid var(--simpleSidebar_popoverBg)
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.8)
    background: var(--simpleSidebar_popoverBg)
    border-radius: 4px
    width: 242px
    overflow: hidden
    position: fixed
    box-sizing: content-box
    transition-duration: 0.3s
    transition-property: top
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1)

    > div
      max-height: inherit

  & :global
    .main-tabs
      border: 0

      .main-tabs-tab
        padding: 0 6px
        margin: 0
        height: 22px
        border: 0
        font-size: 11px
        color: rgba(var(--base_textColor), 0.9)
        border-radius: 0

        &:before,
        &:after
          content: none

        &:hover
          color: rgb(var(--secondaryColor))

        &.active
          box-shadow: 1px 0 0 var(--base_borderColor), -1px 0 0 var(--base_borderColor)
          color: var(--base_mainTabActiveColor)

.selectorContent
  border: 1px solid var(--base_borderColor)
  padding-right: 0 !important

  li
    list-style: none

  & :global
    .sport-item-header
      pointer-events: none
      background: var(--sidebar_liveSportItemBgOpened)
      border-top: var(--sidebar_liveSportItemBorderTop)
      border-bottom: 1px solid var(--sidebar_liveSportItemBorderBottom)
      margin-top: 0!important

      > i:nth-child(2)
        display: none
        visibility: hidden

      &:before
        content: none

      + div
        > div
          border-bottom: 0
        ul > li:last-of-type > div:last-of-type
          border-bottom: 0
  > section > div > a:last-of-type
    border-bottom: 0
  + div
    background: transparent
    border: 0
    right: -1px

    > div
      background: rgba(255, 255, 255, 0.1)
