@use "mixins" as *
.sideBarHeader
  min-width: 240px
  width: calc(100% - 10px)
  padding-top: var(--sidebar_headerPadding)
  overflow: visible !important
  margin-bottom: var(--sidebar_headerMargin)
  box-shadow: 0 calc(var(--sidebar_headerMargin) + 1px) var(--body_bg)

  &:has(.sportBtn.active)
    border-bottom-color: var(--sidebar_tabSportBtnActiveHeaderBorderColor)

.sportBtn,
.liveBtn
  color: rgba(var(--base_textColor), 0.8)
  width: 50%
  height: var(--sidebar_tabHeight)
  @include flex
  justify-content: center
  text-transform: var(--base_textTransform)
  font-size: var(--button_fontSize)
  @include bold
  flex: 1
  border-radius: var(--base_mainTabRadius)

  &:hover:not(.active)
    color: rgb(var(--primaryColor))
    @include pointer

  &.active
    pointer-events: none

.sportBtn.active
  background: var(--sidebar_tabSportBtnActive)
  color: var(--button_color)
  box-shadow: var(--sidebar_tabSportActiveShadow)

.liveBtn.active
  background: var(--base_mainTabActiveBg)
  color: var(--base_mainTabActiveColor)
  box-shadow: var(--sidebar_tabActiveShadow)
