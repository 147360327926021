@use "mixins" as *
.animateSideBar
  display: flex
  position: relative
  z-index: 100

.sideBar
  width: 250px
  min-width: 250px
  order: 1
  opacity: 1

.rightSide
  margin-top: calc((var(--sidebar_tabHeight) + var(--sidebar_headerPadding) + var(--sidebar_headerMargin)) * -1)
  height: 100%
  flex: 1 100%

.scrollableContent
  padding-top: calc(var(--sidebar_tabHeight) + var(--sidebar_headerPadding) + var(--sidebar_headerMargin))
  min-height: 100%
