@use "mixins" as *
.rightSide
  composes: rightSide from "../bets/betslip-bets.module.sass"

.rightSideContent
  composes: rightSideContent from "../bets/betslip-bets.module.sass"
  padding-bottom: 54px

.content
  min-height: 250px
  padding: 8px
  .spinner svg
    width: 120px
    height: 120px
  :global(.no-data)
    color: var(--betslip_noItemColor)
    @include flex
    flex-direction: column
    justify-content: center
    height: 182px
    margin-top: 50px
    padding-top: 28px
    &:before
      content: var(--noDataContent)
      margin-bottom: 8px
      width: 86px
      height: 100px
      background: var(--icon-no-bets) no-repeat

.bet
  border-radius: 3px
  background: var(--betslip_historyBetBg)
  margin-bottom: 4px
  overflow: hidden
  font-size: var(--base_smallFontSize)
  padding: 0 8px

  > div
    @include flex
  :global(.svg-icon.svg-statistics)
    margin: 0 !important
    &:hover
      @include pointer
      :global(.statistics-svg-bg)
        fill: rgb(var(--secondaryColor)) !important

.matchInfoBtn
  background: unset
  width: unset
  height: unset
  padding: 0
  margin: -2px -1px 0 6px

.header
  background: var(--betslip_historyBetHeaderBg)
  height: 21px
  padding: 0 8px
  margin: 0 -8px

  .label
    margin-right: auto

  :global(.svg-icon)
    margin-right: 6px

  .bonusLabel
    background: #ffd801
    border-radius: 1px
    font-size: 10px
    @include flex
    justify-content: center
    color: #21262c
    text-transform: uppercase
    @include bold
    padding: 2px 3px
    line-height: 9px
    white-space: nowrap
    margin-right: -8px

.label
  color: rgb(var(--primaryColor))

.subheader
  height: 24px
  justify-content: space-between
  color: rgb(var(--secondaryColor))
  border-bottom: 1px dotted var(--betslip_historyBetSubHeaderBorder)
  margin-bottom: 4px

.info
  flex-direction: column
  align-items: flex-start

  > p
    @include flex
    width: 100%

    :global(.svg-sport-icon)
      margin-right: 6px

      svg
        width: 14px
        height: 14px
        path
          fill: rgb(var(--secondaryColor)) !important

    &:first-of-type
      margin-bottom: 5px
      font-size: 12px
      @include bold

      .teams
        @include ellipsis
        margin-right: auto
        color: rgb(var(--secondaryColor))

    &:last-of-type
      margin-top: 2px

.odds
  text-transform: capitalize
  color: rgb(var(--secondaryColor))
  margin: 4px 0

  > strong
    margin-left: auto
    background: var(--betslip_historyOddBg)
    color: var(--betslip_historyOddColor)
    height: 14px
    @include flex
    justify-content: center
    min-width: 30px
    padding: 0 4px
    border-radius: 2px

.stake
  padding: 6px 0
  border-top: 1px dotted var(--betslip_historyBetSubHeaderBorder)

.payout
  padding-bottom: 6px

.stake,
.payout
  color: rgb(var(--secondaryColor))
  justify-content: space-between

.icon-lose,
.icon-loss,
.icon-losevoid
  border: 1px solid rgba(#eb5757, 0.7)

.icon-win,
.icon-winvoid
  border: 1px solid rgba(#27ae60, 0.7)

.detailsBtn
  height: 17px
  background: var(--betslip_historyDetailsBtnBg)
  margin: 0 -8px
  justify-content: center
  position: relative

  &:before,
  &:after
    content: ""
    position: absolute
    @include arrowIcon
    opacity: 0.8
    transition: all 0.1s
  &:before
    left: 9px
  &:after
    right: 9px
  &:hover
    color: rgb(var(--secondaryColor))
    cursor: pointer
    &:before,
    &:after
      opacity: 1
  &.opened
    &:before,
    &:after
      transform: rotate(180deg)

.details
  align-items: flex-start !important
  margin: 0 -4px
  overflow: hidden
  > div
    width: 100%
    padding: 2px
    border-radius: 3px
    background: var(--betslip_historyDetailBg)
    margin-bottom: 4px
    .info
      display: flex
      background: var(--betslip_historyDetailInfoBg)
      border-radius: 3px
      padding: 6px 6px 0

      &:not(:first-of-type)
        margin-top: 2px

      .odds > strong
        background: transparent
        min-width: unset
        padding: 0
        color: rgb(var(--secondaryColor))
      :global(.svg-status-icon)
        margin-right: 6px
        @include flex
        > span
          @include flex

.viewAllBets
  padding: 8px 8px 24px
  border-top: 1px solid var(--betslip_borderDark)
  box-shadow: inset 0 1px var(--betslip_borderLight)
  background: var(--betslip_bg)
  position: relative
  z-index: 10
  top: -57px
  width: calc(100% - var(--scroll_width))
  &:after
    content: var(--base_beforeContent)
    height: 1px
    border-bottom: 1px solid var(--betslip_borderDark)
    border-top: 1px solid var(--betslip_borderDark)
    box-shadow: 0 1px var(--betslip_borderLight)
    background: var(--betslip_borderLight)
    position: absolute
    width: 100%
    left: 0
    bottom: 14px
  a
    height: var(--button_height)
    background: rgb(var(--particularColor))
    border-radius: var(--base_borderRadius)
    padding: 0 8px
    @include flex
    @include bold
    justify-content: center
    font-size: 12px
    color: var(--button_color)

    &:hover:not(:active)
      background: var(--button_secondaryHoverBg)

    &:active
      background: var(--button_secondaryActiveBg)
