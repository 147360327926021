@use "mixins" as *
.categoryItemsList
  overflow: hidden

.region
  @include flex
  background: var(--sidebar_regionBg)
  border-bottom: 1px solid var(--sidebar_regionBoderBottom)
  border-top: 1px solid var(--sidebar_regionBoderTop)
  height: var(--sidebar_sportItemHeight)
  padding: 0 8px
  font-size: var(--sidebar_sportItemFontSize)

  :global(.svg-region-icon)
    margin-right: 10px
    margin-left: 2px

  &:hover,
  &.opened
    background: var(--sidebar_regionBgOpened)
    border-top-color: var(--sidebar_regionBgOpenedBorderTop)
    @include pointer
    color: rgb(var(--secondaryColor))

    .gameCount
      color: rgb(var(--secondaryColor))

.gameCount
  background: var(--sidebar_gameCountBg)
  border: 1px solid var(--sidebar_gameCountBorder)
  box-shadow: var(--sidebar_gameCountShadow)
  width: 36px
  height: 20px
  margin-top: -1px
  @include flex
  justify-content: center
  font-size: var(--base_fontSize)
  font-weight: var(--sidebar_gameCountFontWeight)
  color: rgba(var(--primaryColor), 0.8)
  margin-left: auto
