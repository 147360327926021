@use "mixins" as *
.container,
.categoryItemsContainer
  overflow: hidden

.categoryItem
  background: var(--sidebar_liveCategoryBg)
  border-top: var(--sidebar_sportItemBorderTop)
  border-bottom: 1px solid var(--sidebar_regionBoderBottom)
  color: var(--sidebar_liveCategoryColor)
  font-size: var(--base_fontSize)
  text-transform: var(--base_textTransform)
  height: var(--sidebar_sportItemHeight)
  @include flex
  padding: 0 8px
  min-width: 226px
  @include pointer
  white-space: nowrap
  transition: all 0.2s ease-in
  position: relative
  z-index: 5

  span:first-of-type
    margin-right: auto
    line-height: 15px

  &:hover,
  &.opened
    color: rgb(var(--secondaryColor))
    background: var(--sidebar_liveCategoryOpenedBg)
    border-top-color: var(--sidebar_liveCategoryBorderTop)
    border-bottom-color: var(--sidebar_liveCategoryBorderBottom)
