@use "mixins" as *
.sport
  composes: sport from "../sport/prematch-sidebar-sport.module.sass"
  text-transform: capitalize

  &.selected
    background: var(--sidebar_topLeauguesSportBgSelected)
    color: rgb(var(--secondaryColor))
    border-top-color: var(--sidebar_topLeauguesSportBorderTopSelected)
    cursor: default

    :global(.svg-sport-icon)
      opacity: 1

    svg
      path
        fill: var(--sidebar_sportItemSelectedIconFill) !important

.loading
  display: flex
  justify-content: center
  align-items: center
  height: 60px
  position: relative
  span
    font-size: 12px

.container
  overflow: hidden

.title
  background: var(--sidebar_topLeauguesBg)
  @include flex
  height: var(--sidebar_sportItemHeight)
  padding: 0 8px
  font-weight: var(--base_fontWeight)
  justify-content: center
  position: relative
  color: rgb(var(--secondaryColor))
  text-transform: uppercase
  border-bottom: 1px solid var(--sidebar_sportItemBorderBottom)
  border-top: var(--sidebar_topLeauguesBorderTop)
  box-shadow: 0 -1px var(--sidebar_sportItemBorderBottom)
  :global(.svg-icon)
    position: absolute
    left: 10px
