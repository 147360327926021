@use "mixins" as *
.oddStatus
  padding: 6px 6px 6px 10px
  margin-top: 0
  color: rgb(var(--secondaryColor))

  span
    background: #a62644
    padding: 2px 6px
    border-radius: 2px

  span.suspended
    color: rgb(var(--thirdaryColor))
    line-height: 14px
    display: block
    padding: 4px 6px
    font-size: 12px
