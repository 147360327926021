@use "mixins" as *
.eventItem
  background: var(--sidebar_liveTournamentGameBg)
  border-top: 1px solid var(--sidebar_liveTournamentGameBorderTop)
  border-bottom: 1px solid var(--sidebar_liveTournamentGameBorderBottom)
  text-shadow: 0 -1px rgba(0, 0, 0, 0.5)
  padding: var(--sidebar_liveTournamentGamePadding)
  color: rgb(var(--primaryColor))
  @include pointer
  white-space: nowrap
  display: block
  transition: all 0.2s ease-in
  position: relative

  .team
    color: rgba(var(--secondaryColor), 0.8)
    line-height: calc(var(--sidebar_sportItemFontSize) + 2px)
    @include flex
    margin-bottom: var(--sidebar_liveTournamentGameTeamMargin)
    font-size: var(--sidebar_sportItemFontSize)

  &:hover:not(.active)
    background: var(--sidebar_liveTournamentGameHoverBg)
    border-top-color: var(--sidebar_liveTournamentGameHoverBorderTop)
    @include pointer

    .team
      color: rgb(var(--secondaryColor))

  .teamName
    max-width: calc(100% - 46px)
    @include ellipsis

  &:hover:not(.multiViewSelected),
  &.active
    border-bottom-color: var(--sidebar_liveTournamentGameHoverBorderBottom)
    > div:first-child:after
      content: ""
      width: 6px
      height: 6px
      background: var(--sidebar_liveTournamentCircleBg)
      border-radius: 100%
      position: absolute
      right: 38px
      top: 14px

.active
  background: var(--sidebar_liveTournamentGameActiveBg)
  border-top-color: var(--sidebar_liveTournamentGameActiveBorderTop)

  .team
    color: var(--sidebar_liveGameActiveColor)
    cursor: default

.score
  color: var(--sidebar_tournamentSelectedColor)
  margin-left: auto

.marketColumn-two_columns > div
  width: calc(100% + 2 * var(--sidebar_otcomeMarginLeft))
  > div
    width: calc((100% - var(--markets_gap)) / 2)

.marketColumn-three_columns > div
  width: calc(100% + 3 * var(--sidebar_otcomeMarginLeft))
  > div
    width: calc((100% - 2 * var(--markets_gap)) / 3)

.market
  width: 100%
  display: flex
  flex-wrap: wrap
  pointer-events: all
  column-gap: var(--markets_gap)
  --outcome_bg: var(--sidebar_outcomeBg)

.matchStatus
  @include flex
  margin-bottom: -2px
  min-height: 15px
  font-size: var(--base_smallFontSize)
  + div > div > div
    border-bottom: 0

.marketsCount
  margin-left: auto
  color: rgb(var(--secondaryColor))

.favorite
  min-width: 14px
  height: 14px
  background-color: var(--star_favoriteFill)
  mask-image: var(--icon-favorite)
  mask-size: 14px 14px
  margin-left: 6px
  margin-top: -2px
  @include pointer
  pointer-events: all
  &:hover,
  &.active
    background: var(--star_favoriteActiveFill)
    background-size: 14px 14px

.multiViewSelected
  border-bottom-color: var(--sidebar_liveTournamentGameHoverBorderBottom)
  background: var(--sidebar_liveTournamentGameHoverBg)
  cursor: default !important

  .team
    color: rgb(var(--secondaryColor))
    cursor: default

  > div:first-child:after
    content: ""
    position: absolute
    z-index: 1
    width: 28px
    height: 24px
    mask-image: var(--icon-multiview-selected)
    background: var(--simpleSidebar_multiViewSelectedIconBg)
    right: 28px
    top: 7px
