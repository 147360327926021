@use "mixins" as *
.stakeItemContainer
  margin-left: 8px
  position: relative
  flex: 1
  display: flex
  flex-wrap: wrap
  > p
    width: 100%
    margin-top: -3px
    line-height: 18px
    min-height: 18px
  input:disabled
    opacity: 0.5
  > div
    flex: 1
  button + span
    width: 100%
    line-height: 12px
    margin-top: 2px

  :global(.svg-icon)
    margin: var(--betslip_stakeItemIconMarginTop) 4px 0 -18px
    min-width: 14px

.stakeItem
  width: 100%
  height: var(--betslip_stakeItemHeight)
  border: 1px solid rgb(var(--secondaryColor))
  background: rgb(var(--secondaryColor))
  border-radius: var(--base_borderRadius)
  color: #21262c
  padding: 3px 24px 3px 6px
  font-weight: var(--base_fontWeight)
  font-size: var(--base_smallFontSize)

.betMaxBtn
  min-width: 76px
  margin-left: 6px
  display: inline-flex
  background: rgba(var(--betlip_betMaxBg), 0.8)
  color: var(--betlip_betMaxColor)
  height: var(--betslip_stakeItemHeight)
  border-radius: var(--base_borderRadius)
  font-size: var(--base_smallFontSize)
  font-weight: var(--sidebar_gameCountFontWeight)

  &:hover
    background: rgb(var(--betlip_betMaxBg))

  &:active
    background: rgba(var(--betlip_betMaxBg), 0.6)
