@use "mixins" as *
.middleAreaHeader
  border-bottom: var(--middleArea_headerBorder)
  background: var(--middleArea_headerBg)
  height: var(--middleArea_headerHeight)
  border-radius: var(--middleArea_headerRadius)
  width: calc(100% - 10px)
  z-index: 100
  position: relative
  text-transform: var(--base_textTransform)
  padding-right: 12px
  @include flex
  font-size: var(--base_fontSize)
  font-weight: var(--base_fontWeight)
  box-shadow: var(--base_shadowForScroll)

  > a
    @include flex
    svg
      margin-right: 12px

    &:hover
      @include pointer
      color: rgb(var(--secondaryColor))

    &:last-of-type
      margin-left: auto

    &.active
      color: var(--middleArea_headerActiveBtnColor)
      svg
        path
          fill: var(--middleArea_headerActiveBtnColor)

.sidebarButton
  width: 30px
  height: 18px
  margin-right: 10px
  padding-left: 3px
  box-shadow: inset -1px 0 var(--middleArea_sidebarButtonBorderLeft)
  border-right: 1px solid var(--middleArea_sidebarButtonBorderRight)

  .arrow
    transform: rotate(90deg)
    margin-top: 6px
    opacity: 0.8
    transform-origin: 46% 50%
    transition: all 0.1s
    display: inline-flex

    &:first-child
      margin-right: -6px
      margin-left: 6px

  &:hover
    @include pointer

    .arrow
      opacity: 1

  &.collapsed .arrow
    transform: rotate(-90deg)

  &.disabled
    pointer-events: none
    opacity: 0.6

.multiViewColumnMode
  display: flex
  height: var(--multiview_toolHeight)
  background: var(--multiview_toolBg)
  box-shadow: var(--multiview_toolShadow)
  border-radius: var(--multiview_toolRadius)
  border: 1px solid var(--multiview_toolBorder)
  margin: 0 5px 0 18px
  overflow: hidden

  > span
    @include flex
    justify-content: center
    height: 100%

    span
      @include flex

    svg
      opacity: 0.85

      path
        fill: rgb(var(--primaryColor))

    &:hover:not(.active)
      @include pointer

      svg
        opacity: 1

    &:first-of-type
      border-right: 1px solid var(--multiview_toolBorder)
      width: var(--multiview_toolWidth)

    &:last-of-type
      width: calc(var(--multiview_toolWidth) - 1px)

    &.active
      background: var(--multiview_toolActiveBg)

      svg
        opacity: 1

        path
          fill: var(--multiview_toolActiveIconFill)

.multiVewRemoveBtn
  background: var(--multiview_toolBg)
  border: 1px solid var(--multiview_toolBorder)
  width: var(--multiview_toolWidth)
  height: var(--multiview_toolHeight)
  box-shadow: var(--multiview_toolShadow)
  @include flex
  justify-content: center
  border-radius: var(--multiview_toolRadius)

  > span
    @include flex

  svg
    opacity: 0.85

    path
      fill: rgb(var(--primaryColor))

  &:hover
    @include pointer

    svg
      opacity: 1

@media only screen and (max-width: 1800px)
  .multiViewColumnMode
    display: none
    pointer-events: none

  .multiVewRemoveBtn
    margin-left: 18px
