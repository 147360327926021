@use "mixins" as *
.betSlipItem
  background: var(--betlip_itemBg)
  border-top: 1px solid var(--betslip_borderDark)
  border-bottom: 1px solid var(--betslip_borderDark)
  color: rgb(var(--primaryColor))
  font-size: var(--base_smallFontSize)
  position: relative

  &:not(:last-of-type)
    margin-bottom: 3px
  :global(.loader)
    top: 0

.removedBetItem
  > *:not(.acceptedBet)
    opacity: 0.6
    pointer-events: none
    input
      background: var(--betslip_removedBetInputBg)
      border-color: var(--betslip_removedBetInputBg)
      color: rgb(var(--secondaryColor))
      &::placeholder
        color: rgb(var(--secondaryColor))

.gameNameRow
  padding: 6px 22px 0 10px
  overflow: hidden
  display: flex

.gameName
  line-height: calc(var(--button_fontSize) + 1px)
  font-weight: var(--base_fontWeight)
  @include ellipsis
  font-size: var(--button_fontSize)
  max-width: 290px
  overflow: hidden
  min-height: var(--betlip_itemRowMinHeight)
  line-height: var(--betlip_itemRowMinHeight)

.closeItem
  background: var(--icon-close) no-repeat
  width: 8px
  height: 8px
  min-width: 8px
  position: absolute
  right: 8px
  @include pointer
  &:hover
    opacity: 0.9

.marketNameContainer
  @include flex
  padding: 0 8px 6px 10px

  > div
    flex: 1 auto

.marketName
  opacity: 0.8
  display: block
  min-height: var(--betlip_itemRowMinHeight)
  line-height: var(--betlip_itemRowMinHeight)

.outcomeName
  display: block
  min-height: var(--betlip_itemRowMinHeight)
  line-height: var(--betlip_itemRowMinHeight)
  span
    color: rgb(var(--secondaryColor))

.outcomeValue
  @include bold
  color: var(--betslip_itemOutcomeValueColor)
  text-shadow: 0 -1px rgba(0, 0, 0, 0.6)
  text-align: center
  margin-top: -1px
  min-width: 32px
  padding: 3px 4px 2px

.outcomeChanged
  background: rgb(var(--particularColor2))
  color: rgb(var(--secondaryColor))
  border-radius: 2px
  text-shadow: none

.stakeRow
  background: var(--betslip_stakeRowBg)
  padding: 4px 6px 4px 10px
  line-height: var(--betslip_stakeItemHeight)
  display: flex
  flex-wrap: wrap
  > span:first-of-type
    min-width: 80px

  &:has(p) > span:first-of-type
    margin-top: 18px

.toWinRow
  padding: 6px 6px 6px 10px
  position: relative
  min-height: 24px
  :global(.loader)
    left: 0
    top: 0
    justify-content: flex-start
    span
      margin: 0 18px
      font-size: 9px !important
  strong
    color: rgb(var(--secondaryColor))
    text-shadow: 0 -1px rgba(0, 0, 0, 0.6)
    display: inline-flex
    margin-left: 4px
    :global(.svg-icon)
      margin: -2px 4px 0

.oddStatus
  padding: 0 6px 6px 10px
  margin-top: 0
  color: rgb(var(--secondaryColor))

  span
    background: #a62644
    color: rgb(var(--secondaryColor))
    padding: 2px 6px
    border-radius: 2px
    display: inline-block
    line-height: 14px

.acceptedBet
  composes: acceptedBet from "../betslip-bets.module.sass"

.spinner
  position: absolute
  z-index: 2
  @include flex
  justify-content: center
  width: 100%
  height: 100%
  top: 0
  left: 0
  background: rgba(#000, 0.4)
  svg
    width: 80px
    height: 80px
  :global(.spinner-color)
    fill: #37d13e
  &.spinnerForSingleBet svg
    width: 120px
    height: 120px
