@use "mixins" as *
.errorArea
  border-top: 1px solid var(--betslip_borderDark)
  border-bottom: 1px solid var(--betslip_borderDark)
  background: #60798f linear-gradient(to bottom, #60798f 0%, #637c92 3%, #627b91 13%, #566e86 94%, #536b83 100%)
  @include flex
  color: rgb(var(--secondaryColor))
  height: 40px
  padding: 0 8px 1px 6px
  :global(.imageNext)
    min-width: 22px
    margin-right: 6px
  &.green
    background: #1d5236 linear-gradient(to bottom, #1d5236 0%, #1a4430 100%)

  &.orange
    background: #947739 linear-gradient(to bottom, #947739 0%, #87672c 100%)

  &.red
    background: #872226linear-gradient(to bottom,#8a2327 9%,#852024 28%,#812125 47%,#802022 50%,#761d21 100%)

.errorText
  display: inline-block
  line-height: 16px
  flex: 1 100%